var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"id") : depth0),"!=",(depths[1] != null ? lookupProperty(depths[1],"fromId") : depths[1]),{"name":"common$compare","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":24},"end":{"line":21,"column":43}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":43},"end":{"line":20,"column":49}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":20,"column":51},"end":{"line":20,"column":59}}}) : helper)))
    + " ("
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currencyIso3") : depth0),(depth0 != null ? lookupProperty(depth0,"totals") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":20,"column":61},"end":{"line":20,"column":107}}}))
    + ")</option>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\r\n    <h2 class=\"pull-left\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"move_from_tab_{0}",(depth0 != null ? lookupProperty(depth0,"fromTab") : depth0),{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":74}}}))
    + "</h2>\r\n    <h2 class=\"pull-right\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currencyIso3") : depth0),(depth0 != null ? lookupProperty(depth0,"totals") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":3,"column":27},"end":{"line":3,"column":73}}}))
    + "</h2>\r\n</div>\r\n<div class=\"dialog--content\">\r\n\r\n    <form onsubmit=\"return false;\" class=\"form-horizontal form-bordered\" style=\"margin: 0;\">\r\n\r\n        <div class=\"form-group row\">\r\n            <label class=\"control-label col-md-4\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"from_tab",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":50},"end":{"line":10,"column":81}}}))
    + "</label>\r\n            <p class=\"form-control-static col-md-8\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"fromTab") || (depth0 != null ? lookupProperty(depth0,"fromTab") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"fromTab","hash":{},"data":data,"loc":{"start":{"line":11,"column":52},"end":{"line":11,"column":63}}}) : helper)))
    + "</p>\r\n        </div>\r\n\r\n        <div class=\"form-group row\">\r\n            <label for=\"to-tab\" class=\"control-label col-md-4\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"to_tab",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":63},"end":{"line":15,"column":92}}}))
    + "</label>\r\n            <div class=\"col-md-8\">\r\n                <select name=\"to-tab\" id=\"to-tab\" data-ui=\"swap-to\" class=\"form-control\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tabs") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":22,"column":29}}})) != null ? stack1 : "")
    + "                </select>\r\n            </div>\r\n        </div>\r\n\r\n    </form>\r\n\r\n</div>\r\n<div class=\"dialog--footer\">\r\n    <button class=\"btn btn-danger btn-regular\" data-action=\"accept\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"move",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":68},"end":{"line":31,"column":95}}}))
    + "</button>\r\n    <button class=\"btn btn-default btn-regular\" data-action=\"close\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"close",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":32,"column":68},"end":{"line":32,"column":96}}}))
    + "</button>\r\n</div>";
},"useData":true,"useDepths":true});