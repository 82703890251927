var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-success btn-regular\" data-action=\"checkout\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"to_checkout",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":75},"end":{"line":20,"column":109}}}))
    + "</button>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-success btn-regular\" disabled>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"empty_tab",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":61},"end":{"line":22,"column":93}}}))
    + "</button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\r\n    <h2 class=\"pull-left\">\r\n        <strong data-action=\"edit\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":43}}}) : helper)))
    + "</strong>\r\n        <span style=\"font-size: 0.5em;\">\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"created_on_{0}_by_{1}_at_{2}",require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_created") : depth0),"L",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":5,"column":62},"end":{"line":5,"column":96}}}),(depth0 != null ? lookupProperty(depth0,"author_name") : depth0),require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_created") : depth0),"HH:mm",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":5,"column":109},"end":{"line":5,"column":147}}}),{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":149}}}))
    + "\r\n        </span>\r\n    </h2>\r\n    <h2 class=\"pull-right\">\r\n        <strong>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"totals") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":63}}}))
    + "</strong>\r\n        <button class=\"btn btn-info btn-regular\" data-action=\"print-full\">\r\n            <i class=\"fas fa-print fa-2x\"></i>\r\n        </button>\r\n    </h2>\r\n</div>\r\n<div class=\"dialog--content\" data-region=\"timeline\"></div>\r\n<div class=\"dialog--footer\">\r\n    <button class=\"btn btn-danger btn-regular\" data-action=\"delete\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"canDelete") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":68},"end":{"line":17,"column":108}}})) != null ? stack1 : "")
    + ">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"delete_tab",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":109},"end":{"line":17,"column":142}}}))
    + "</button>\r\n    <button class=\"btn btn-warning btn-regular\" data-action=\"swap\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"move_tab",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":67},"end":{"line":18,"column":98}}}))
    + "</button>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasOrderItems") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "    <button class=\"btn btn-default btn-regular\" data-action=\"close\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"close",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":68},"end":{"line":24,"column":96}}}))
    + "</button>\r\n</div>";
},"useData":true});