var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"table_order") : depth0)) != null ? lookupProperty(stack1,"value_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":69}}}))
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"available",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":40}}}))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<strong class=\"name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":1,"column":21},"end":{"line":1,"column":29}}}) : helper)))
    + "</strong>\r\n<span class=\"creator\">"
    + alias4(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"table_order") : depth0)) != null ? lookupProperty(stack1,"created_by") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\r\n<span class=\"availability "
    + alias4(((helper = (helper = lookupProperty(helpers,"availabilityClass") || (depth0 != null ? lookupProperty(depth0,"availabilityClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"availabilityClass","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":47}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"table_order") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "</span>\r\n<span class=\"icon\"></span>\r\n<div class=\"buttons\">\r\n    <button class=\"btn btn-info w-100\" data-action=\"add-to-table\">\r\n        <i class=\"fas fa-2x fa-plus-square text-light\"></i>\r\n    </button>\r\n</div>\r\n";
},"useData":true});