var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--header\">\r\n    <h2 class=\"pull-left\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"tab_overview",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":61}}}))
    + "</h2>\r\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n</div>\r\n<div class=\"dialog--content\">\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-6\" data-region=\"search\"></div>\r\n        <div class=\"col-xs-2\">\r\n            <button class=\"btn btn-warning btn-block form-control\" data-action=\"reset\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"clear_search",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":87},"end":{"line":9,"column":122}}}))
    + "</button>\r\n        </div>\r\n        <div class=\"col-xs-4\">\r\n            <button class=\"btn btn-success btn-block form-control\" data-action=\"add\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"add_a_new_tab",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":85},"end":{"line":12,"column":121}}}))
    + "</button>\r\n        </div>\r\n    </div>\r\n    <br>\r\n    <div class=\"text-center\">\r\n        <small>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"last_changed_tabs_are_on_top",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":15},"end":{"line":17,"column":66}}}))
    + "</small>\r\n    </div>\r\n    <div data-region=\"list\"></div>\r\n    <div class=\"text-center\">\r\n        <small>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"scrollswipe_down_to_see_more",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":15},"end":{"line":21,"column":66}}}))
    + "</small>\r\n    </div>\r\n</div>\r\n<div class=\"dialog--footer full-child-height\">\r\n    <button class=\"btn btn-default btn-regular\" data-action=\"close\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"close",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":68},"end":{"line":25,"column":96}}}))
    + "</button>\r\n</div>";
},"useData":true});