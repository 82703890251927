var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "text-success";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"name text-ellipsis-wrap\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":45}}}) : helper)))
    + "</div>\r\n<div class=\"totals "
    + ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"totals") : depth0),">",0,{"name":"common$compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":19},"end":{"line":2,"column":82}}})) != null ? stack1 : "")
    + " text-right\">\r\n    "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"totals") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":51}}}))
    + "\r\n</div>\r\n<div class=\"last text-ellipsis\">\r\n    "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_updated") : depth0),"L",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":40}}}))
    + " | "
    + alias4(((helper = (helper = lookupProperty(helpers,"last_author_firstname") || (depth0 != null ? lookupProperty(depth0,"last_author_firstname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"last_author_firstname","hash":{},"data":data,"loc":{"start":{"line":6,"column":43},"end":{"line":6,"column":68}}}) : helper)))
    + "\r\n</div>\r\n<div class=\"info full-child-height\">\r\n    <button class=\"btn btn-info\" data-action=\"info\"><i class=\"fa fa-info\"></i> "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"information",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":79},"end":{"line":9,"column":113}}}))
    + "</button>\r\n</div>\r\n<div class=\"add full-child-height\">\r\n    <button class=\"btn btn-success\" data-action=\"add-order-item\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"addable") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":65},"end":{"line":12,"column":103}}})) != null ? stack1 : "")
    + "><i class=\"fa fa-plus\"></i> "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"add",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":131},"end":{"line":12,"column":157}}}))
    + "</button>\r\n</div>";
},"useData":true});