var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled ";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-danger btn-regular\" data-action=\"close-table\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"close_table",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":77},"end":{"line":12,"column":111}}}))
    + "</button>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-success btn-regular\" data-action=\"pay\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"pay",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":70},"end":{"line":14,"column":96}}}))
    + "</button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\r\n    <h2 class=\"pull-left\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"table_name") || (depth0 != null ? lookupProperty(depth0,"table_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"table_name","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":40}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"table_area_name") || (depth0 != null ? lookupProperty(depth0,"table_area_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"table_area_name","hash":{},"data":data,"loc":{"start":{"line":2,"column":42},"end":{"line":2,"column":61}}}) : helper)))
    + ")</h2>\r\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n</div>\r\n<div class=\"dialog--content\" data-region=\"order-parts\"></div>\r\n<div class=\"dialog--footer\">\r\n    <button class=\"btn btn-info btn-regular\" data-action=\"print-summary\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"order_empty") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":73},"end":{"line":7,"column":109}}})) != null ? stack1 : "")
    + ">"
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"print_summary",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":110},"end":{"line":7,"column":146}}}))
    + "</button>\r\n    <button class=\"btn btn-regular\" data-action=\"\"></button>\r\n    <button class=\"btn btn-warning btn-regular\" data-action=\"move\">"
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"move_table",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":67},"end":{"line":9,"column":100}}}))
    + "</button>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"order_empty") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "\r\n</div>\r\n";
},"useData":true});